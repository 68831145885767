import React, {useState} from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';

function App() {
  const [file, setFile] = useState(null)
  const [processState, setProcessState] = useState("start")


  const downloadFinal = async () => {
    setProcessState("loading")
    fetch(`http://localhost:8080/download?filename=${file.name}`)
    .then(res => res.blob())
    .then(blob =>{
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
    })
    setProcessState("start")
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessState("loading")
    let formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        "http://localhost:8080/upload",
        formData
      );
      console.log(response.data);
      setProcessState('download')

    } catch (ex) {
      console.log(ex);
      setProcessState('start')
    }
    }
    const handleChange = (e) => {
      setFile(e.target.files[0])
      console.log(e.target.files[0])
  }

  const processRoute = () => {
    if (processState === 'loading') {
      return <Loading />
    } else if (processState === 'start') {
      return <UploadImage handleSubmit={handleSubmit} handleChange={handleChange} file={file} />
    } else if (processState === 'download') {
      return <Download  downloadFinal={downloadFinal} />
    }
  }

  return (
    <>
      <section className="w-screen h-screen min-h-screen bg-gradient-to-l from-blue-200 via-blue-300 to-blue-500">
        <Navbar />
        <header className="flex flex-col items-center justify-between pt-6 md:px-40 md:flex-row h-5/6 md:py-16">
          <div className="text">
            <h1 className="text-lg font-bold text-center text-white md:text-left md:text-4xl md:leading-tight"><span className="text-yellow-200">IM</span>PRESSOR COMPRESSES <br/>
              YOUR IMAGES TO RETAIN <br/>
              <span className="text-yellow-200">QUALLITY</span> WHEN POSTING <br/>
              ON <span className="text-yellow-200">SOCIAL MEDIA</span></h1> 
          </div>
          <div className="flex flex-col items-center justify-center h-full px-12 mt-6 text-center bg-white shadow-md action md:w-1/2 md:py-4 md:mt-0 rounded-xl">
              <div className="header">
                <h1 className="text-2xl font-bold text-blue-400 uppercase title">Universal Impressoring</h1>
                <p className="text-sm text-gray-500 uppercase">For Facebook, WhatsApp and instagram</p>
              </div>
              <div className="mt-8 workarea md:mt-20">
                {
                processRoute()
                }
              </div>
          </div>
        </header>
    </section>
    </>
  );
}

export default App;

const UploadImage = (props) => {
  return (
    <>
      <form className="justify-center w-full upload" onSubmit={props.handleSubmit}>
        <label className="flex flex-col items-center px-4 py-6 tracking-wide uppercase bg-gray-100 border border-blue-200 rounded-lg shadow-lg cursor-pointer w-80 text-blue hover:bg-blue hover:text-white">
          <svg className="w-8 h-8 text-blue-300" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>
          {
            (props.file) ? (
              <span className="mt-2 text-sm text-base leading-normal text-green-500 delay-150">Image uploaded: {props.file.name}</span>
            ) : (
                <span className="mt-2 text-base leading-normal text-blue-500 delay-150">Select an image</span>
            )
          }
          <input type='file' className="hidden" name="image" id="image" onChange={(e) => props.handleChange(e)} />
        </label>
        <input className="mt-8 text-white bg-gradient-to-l w-full delay-1.5 cursor-pointer from-blue-300 via-blue-400 rounded py-2 px-6 font-semibold to-blue-500" type="submit" value="IMPRESSOR THE IMAGE" />
      </form>
    </>
  )
}
const Download = (props) => {
  return (
    <>
      <div className="justify-center w-full upload" onSubmit={props.handleSubmit}>
        <img src={props.dowloadUrl} className="w-full h-auto" alt="" />

        <button onClick={props.downloadFinal} className="mt-8 text-white bg-gradient-to-l w-full delay-1.5 cursor-pointer from-blue-300 via-blue-400 rounded py-2 px-6 font-semibold to-blue-500" >DOWNLOAD IMAGE</button>
      </div>
    </>
  )
}

const Loading = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <svg className="w-8 h-8 mr-3 -ml-1 text-blue-300 animate-spin md:h-12 md:w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
        <h2 className="mt-4 text-xl font-semibold text-center text-blue-500">Impressoring...</h2>
      </div>
    </>
  )
}
